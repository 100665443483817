import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import FluidImage from "../../components/FluidImage"
import { Column, Wrapper, Row } from "../../global/grid"
import { col } from "../../global/theme"


const Post = ({ pageContext }) => {
  const {
    post: { title, content, featuredImage },
  } = pageContext

  return (
    <Layout>
      <SEO title={title}/>
        <Wrapper>
            <Row paddedRow>
              <Column selfStart xsWidth={col.twelve} mdWidth={col.eight}>
                <FluidImage image={featuredImage} style={{ marginBottom: "15px" }}/>
                <div dangerouslySetInnerHTML={{ __html: content }}/>
              </Column>
            </Row>
      </Wrapper>
    </Layout>
  )
}

export default Post
